import { pruneNilValues } from '@core/object';
/**
 * Maps session for tracking events
 */
export const prepareSessionFields = (session) => {
    return pruneNilValues({
        session_id: session.id,
        session_type: session.type,
        event_properties: {
            session_count: session.session_count,
        },
    });
};
