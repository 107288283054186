import { Fragment, lazy, Suspense } from 'react';
export { AccessibleSVG } from './AccessibleSVG';
export { ShadowFilter } from './ShadowFilter';
const InjectableSVGComponent = lazy(() => import('./InjectableSVG'));
/**
 * Lazy loaded version of InjectableSVG component.
 */
export const InjectableSVG = (props) => (<Suspense fallback={<Fragment />}>
    <InjectableSVGComponent {...props}/>
  </Suspense>);
