/**
 * Interface providing a set of methods to control
 * a player instance.
 */
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _PlayerControl_instance;
/**
 * PlayerControl is a class that provides a set of methods to programmatically control
 * a player instance via `_fwn.player` or `_fwn.players`.
 */
export class PlayerControl {
    /**
     *
     */
    constructor(instance) {
        _PlayerControl_instance.set(this, void 0);
        __classPrivateFieldSet(this, _PlayerControl_instance, instance, "f");
    }
    /**
     * Get the name of the widget the player belongs to (was initialized by).
     */
    get name() {
        return __classPrivateFieldGet(this, _PlayerControl_instance, "f").name;
    }
    /**
     * Provide read only access to API video object
     */
    get video() {
        return __classPrivateFieldGet(this, _PlayerControl_instance, "f").video;
    }
    /**
     *
     */
    get currentTime() {
        return __classPrivateFieldGet(this, _PlayerControl_instance, "f").videoWrapper?.currentTime || 0;
    }
    /**
     *
     */
    set currentTime(currentTime) {
        if (__classPrivateFieldGet(this, _PlayerControl_instance, "f").videoWrapper) {
            __classPrivateFieldGet(this, _PlayerControl_instance, "f").videoWrapper.currentTime = currentTime;
        }
    }
    /**
     *
     */
    get muted() {
        return __classPrivateFieldGet(this, _PlayerControl_instance, "f").videoWrapper.muted;
    }
    /**
     *
     */
    set muted(value) {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").videoWrapper.muted = value;
    }
    /**
     *
     */
    mute() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handleMute();
    }
    /**
     *
     */
    unmute() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handleUnmute();
    }
    /**
     *
     */
    close() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handleClose();
    }
    /**
     *
     */
    minimize() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handleMinimize();
    }
    /**
     *
     */
    fullscreen() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handleMaximize();
    }
    /**
     *
     */
    play() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handlePlay();
    }
    /**
     *
     */
    pause() {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").handlePause();
    }
    /**
     * Register an async callback to be called when an event occurs.
     */
    on(event, fn) {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").registerCallback(event, fn);
    }
    /**
     * Unregister a callback from an event.
     */
    off(event) {
        __classPrivateFieldGet(this, _PlayerControl_instance, "f").unregisterCallback(event);
    }
}
_PlayerControl_instance = new WeakMap();
