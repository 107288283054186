import { mergeVariables } from '../tools/mergeVariables';
import { theme as defaultTheme } from './default';
export const theme = {
    key: 'dark',
    variables: mergeVariables(defaultTheme.variables, [
        ['--font-color', 'var(--white)'],
        ['--player-chat-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--player-shop-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--player-interaction-background', 'var(--blk1)'],
        ['--player-interaction-font-color', 'var(--white)'],
        ['--player-modal-background', 'var(--blk1)'],
        ['--player-modal-font-color', 'var(--font-color)'],
        [
            '--player-drawer-background',
            'color-mix(in srgb, var(--blk1) 85%, transparent)',
        ],
        ['--player-drawer-font-color', 'var(--font-color)'],
        // Product Card
        ['--product-card-background', 'rgb(var(--blk2-rgb) / 90%)'],
        ['--product-card-font-color', 'var(--font-color)'],
        ['--product-name-font-color', 'var(--font-color)'],
        ['--product-price-font-color', 'var(--font-color)'],
    ]),
};
