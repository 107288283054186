/**
 * ENV object for Next's' client and server
 * DefinePlugin replacement.
 */
const NEXTJS_ENV = typeof process !== 'undefined' && process?.env?.NEXT_PUBLIC_IS_NEXTJS
    ? {
        IS_NEXTJS: process.env.NEXT_PUBLIC_IS_NEXTJS,
        API_HOST: process.env.NEXT_PUBLIC_API_HOST,
        IMG_RESIZING_HOST: process.env.NEXT_PUBLIC_IMG_RESIZING_HOST,
        API_HOST_ADS: process.env.NEXT_PUBLIC_API_HOST_ADS,
        PIXEL_HOST: process.env.NEXT_PUBLIC_PIXEL_HOST,
        PIXEL_NABOO_HOST: process.env.NEXT_PUBLIC_PIXEL_NABOO_HOST,
        PRODUCT: process.env.NEXT_PUBLIC_PRODUCT,
        TRACK_VERSION: process.env.NEXT_PUBLIC_TRACK_VERSION,
        RELEASE_VERSION: process.env.NEXT_PUBLIC_RELEASE_VERSION,
    }
    : {};
/**
 * Get Next's public env variable from `process.env`. Protects
 * agains `process` not being defined in the browser.
 */
export function getNextJsPublicEnv(key) {
    return NEXTJS_ENV[key];
}
/**
 * Shortcut to determine if current runtime is Next.js.
 */
export function isNextJs() {
    return !!getNextJsPublicEnv('IS_NEXTJS');
}
export const defaultApiHost = getNextJsPublicEnv('API_HOST') ?? process.env.API_HOST ?? '';
const friendlyNumberFormatter = new Intl.NumberFormat('en', {
    notation: 'compact',
    compactDisplay: 'short',
});
/**
 * Formatting a number to a friendly format.
 * @param num - number to format
 * @returns string
 * @example
 * friendlyFormatNumber(1000) // 1k
 * friendlyFormatNumber(1500) // 1.5k
 * friendlyFormatNumber(1000000) // 1M
 */
export const friendlyFormatNumber = (num) => friendlyNumberFormatter.format(num);
