/**
 * List of shared global variables to be used in Zeffo codebase.
 *
 * Note: Do not link `--fw-{name}` variables directly, rather create a new
 *       variable and link it to `--fw-{name}` via `cssVar`.
 */
export const VARIABLES = [
    ['--primary', { fallback: '#0f6bff', external: true }],
    ['--secondary', { fallback: '#121212', external: true }],
    [
        '--gradient',
        {
            fallback: 'linear-gradient(97.33deg, var(--primary) -57.16%, var(--primary) 149.26%)',
            external: true,
        },
    ],
    ['--background', { fallback: 'rgb(0 0 0 / 80%)', external: true }],
    ['--badge-highlight', { fallback: '#e50b69', external: true }],
    // Button
    // Deprecated: Do not refer to --button-color in components. We need to support them in external but should promote --primary-button-background in future
    ['--button-color', { fallback: 'var(--gradient)', external: true }],
    // Deprecated: Do not refer to --button-font-color in components. We need to support them in external but should promote --primary-button-color in future
    [
        '--button-font-color',
        {
            fallback: 'var(--white)',
            external: true,
        },
    ],
    // Primary button
    [
        '--primary-button-background',
        { fallback: 'var(--button-color)', external: true },
    ],
    [
        '--primary-button-color',
        { fallback: 'var(--button-font-color)', external: true },
    ],
    ['--primary-button-border-radius', { external: true }],
    ['--primary-button-font-family', { external: true }],
    ['--primary-button-animation'],
    ['--primary-button-border', { fallback: 'none', external: true }],
    // CTA button
    ['--cta-button-animation', { external: true }],
    ['--cta-button-background', { external: true }],
    ['--cta-button-border', { external: true }],
    ['--cta-button-border-radius', { external: true }],
    ['--cta-button-color', { external: true }],
    ['--cta-button-font-size', { external: true }],
    ['--cta-button-font-weight', { external: true }],
    ['--cta-button-height', { external: true }],
    ['--cta-button-padding', { external: true }],
    ['--font-color', { fallback: 'var(--white)', external: true }],
    [
        '--font-family',
        {
            fallback: 'Avenir Next, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
            external: true,
        },
    ],
    ['--branding-text-align', { external: true }],
    ['--branding-text-color', { fallback: 'inherit', external: true }],
    /**
     * Ad Badge
     */
    ['--ad-badge-background', { external: true }],
    ['--ad-badge-font-color', { external: true }],
    /**
     * Player Deck
     */
    [
        '--player-deck-product-card-font-color',
        { external: true, fallback: 'var(--blk0)' },
    ],
    ['--player-deck-product-card-font-size', { external: true }],
    ['--player-deck-product-card-font-weight', { external: true }],
    ['--player-deck-product-card-discount-price-font-color', { external: true }],
    /**
     * Product Card
     */
    ['--product-card-image-object-fit', { external: true, fallback: 'cover' }],
    /**
     * Interactions
     */
    [
        '--player-interaction-font-color',
        {
            external: true,
            fallback: 'var(--blk1)',
        },
    ],
    [
        '--player-interaction-background',
        {
            external: true,
        },
    ],
];
