import { EventType, postMessageWrapper } from '@core/customEvent';
import { PostmessageSentinel } from '@core/customEvent/postMessageWrapper';
import { PIP_IFRAME_NAME } from '@core/pictureInPicture/constants';
import { PIP } from './pip';
/**
 * Attach PIP controller to window object.
 */
export function attach() {
    if (typeof window !== 'undefined' &&
        !Object.prototype.hasOwnProperty.call(window._fwn, 'pip')) {
        Object.defineProperty(window._fwn, 'pip', {
            value: new PIP(),
            writable: false,
        });
        /**
         * For FWN script executed in PIP iframe, send a message to parent to indicate
         * PIP iframe has been loaded. This is to mitigate the case where `load` event
         * on iframe can take a very long time to fire.
         */
        if (window.name === PIP_IFRAME_NAME) {
            postMessageWrapper({
                sentinel: PostmessageSentinel.INTERNAL,
                action: EventType.FW_PIP_READY,
            });
        }
    }
}
