export var Api_Video_InteractionTypeEnum;
(function (Api_Video_InteractionTypeEnum) {
    Api_Video_InteractionTypeEnum["QUESTION"] = "question";
    Api_Video_InteractionTypeEnum["POLL"] = "poll";
    Api_Video_InteractionTypeEnum["APPOINTMENT"] = "appointment";
    Api_Video_InteractionTypeEnum["QUIZ"] = "quiz";
    Api_Video_InteractionTypeEnum["GIVEAWAY"] = "giveaway";
    Api_Video_InteractionTypeEnum["TRIVIA_GIVEAWAY"] = "trivia_giveaway";
    Api_Video_InteractionTypeEnum["TRIVIA_GIVEAWAY_QUESTIONS"] = "trivia_giveaway_questions";
    Api_Video_InteractionTypeEnum["TRIVIA_GIVEAWAY_ANSWERS"] = "trivia_giveaway_answers";
    Api_Video_InteractionTypeEnum["LINK"] = "link";
})(Api_Video_InteractionTypeEnum || (Api_Video_InteractionTypeEnum = {}));
