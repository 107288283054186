import { lens } from '@dhmk/zustand-lens';
import { createAvaGlobalActions } from './actions';
import { createAvaState } from './state';
export const AVA_SLICE_KEY = 'ava';
/**
 *
 */
export function createAvaGlobalSlice() {
    return {
        [AVA_SLICE_KEY]: lens((set) => ({
            ...createAvaState(),
            actions: createAvaGlobalActions(set),
        })),
    };
}
