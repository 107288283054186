import { useCallback } from 'react';
import { pruneNilValues } from '@core/object';
import { appContextToTrackingDataPixel, sendPixelTrackingWithSession, } from '@core/tracking';
import { getVideoPlayId } from '@core/tracking/videoPlayIdTracking';
import { useStoreApi } from '@core/widgets/store';
import { FEED_CREATE_THUMBNAIL_IMPRESSION } from '@core/widgets/tracking/endpoints';
/**
 * Sends thumbnail impression event for a set of videos, if any.
 */
export const useTrackThumbnailImpression = () => {
    const storeApi = useStoreApi();
    return useCallback((videos, videoPlaySourceType, appContext) => {
        if (videos.length === 0) {
            return;
        }
        const { params } = storeApi.getState();
        const pixelTrackingData = appContextToTrackingDataPixel({ appContext });
        const pixelData = {
            event_properties: pruneNilValues({
                autoplay: params.autoplay,
                placement: params.placement,
                ui_player_style: params?.ui_player_style,
                ui_theme: params?.theme || params?.ui_theme,
                videos: videos.map((video) => pruneNilValues({
                    _video_id: video.encoded_id,
                    _live_stream_id: video.live_stream_id,
                    live_stream_status: video.live_stream_status,
                    variant: video.variant,
                    video_play_id: getVideoPlayId(params?.widget_id ?? '', video.encoded_id ?? '', videoPlaySourceType),
                })),
                ...pixelTrackingData,
            }),
        };
        sendPixelTrackingWithSession({
            endpoint: FEED_CREATE_THUMBNAIL_IMPRESSION,
            data: pixelData,
            host: params.api_host_tracking,
        });
    }, [storeApi]);
};
