/**
 * List of embed-feed variables.
 */
export const VARIABLES = [
    ['--thumbnail-border-radius', { external: true }],
    ['--thumbnail-font-size', { external: true }],
    ['--thumbnail-font-weight', { external: true }],
    ['--thumbnail-gap', { external: true }],
    ['--thumbnail-height', { external: true }],
    ['--thumbnail-width', { external: true }],
    ['--thumbnail-aspect-ratio', { external: true }],
    ['--thumbnail-autoplay-object-fit', { external: true }],
    ['--thumbnail-object-fit', { external: true }],
    /**
     * Product Carousel
     */
    ['--player-deck-border-color'],
    ['--player-deck-list-background'],
    [
        '--player-deck-item-background',
        {
            fallback: 'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%)',
        },
    ],
    [
        '--player-deck-item-background-hover',
        {
            fallback: 'linear-gradient(135deg, rgba(241, 241, 241, 0.81) 0.01%, rgba(241, 241, 241, 0.9) 62%)',
        },
    ],
    ['--player-deck-item-font-color'],
    ['--player-deck-product-card-discount-price-font-color'],
    ['--player-deck-product-drawer-trigger-foreground'],
    ['--player-deck-product-drawer-trigger-background'],
    /**
     * CTA
     */
    [
        '--player-deck-cta-background-color',
        {
            fallback: 'var(--white)',
        },
    ],
    [
        '--player-deck-cta-button-color',
        {
            fallback: 'var(--blk0)',
        },
    ],
];
