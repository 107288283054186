import { useMemo } from 'react';
import { useChannel } from '@core/channel';
import { FeedQueryWrapper } from '@core/feed/FeedQueryWrapper';
import { useMapParamsToConfiguration } from '@core/widgets/embedFeed';
import { useStore } from '@core/widgets/store';
import { embedConfigParamsSelector, paramsSelector, } from '@core/widgets/store/selectors';
import { useCohorts } from '@embed/hooks/useCohorts';
import { useWidgetConfigId } from '@embed/hooks/useWidgetConfigId';
/**
 * HOC to resolve embed feed config and render wrapper with feed context
 */
export const WidgetFeed = ({ appContextType, children, }) => {
    const params = useStore(paramsSelector);
    const cohortParams = useStore((state) => state.fromCohortParams);
    const embedConfigParams = useStore(embedConfigParamsSelector);
    useWidgetConfigId({ widget_config_id: params.widget_config_id });
    const channel = useChannel({
        username: embedConfigParams?.channel || params.channel,
        encodedId: params.channel_id,
        target: params.target,
        widgetConfigId: params.widget_config_id,
        embedConfigParams,
    });
    const mergedParams = useMemo(() => ({ ...embedConfigParams, ...params, ...cohortParams }), [cohortParams, embedConfigParams, params]);
    const configs = useMapParamsToConfiguration({
        appContextType,
        params: mergedParams,
        channel,
    });
    useCohorts({ channel });
    if (!configs?.length) {
        return null;
    }
    return (<FeedQueryWrapper configs={configs} params={params}>
      {children}
    </FeedQueryWrapper>);
};
