import { isTruthy } from '@core/helpers/boolean';
const URL_PREFIX = 'fwflag_';
let flags;
/**
 * Search URL for feature flags and return them as an object.
 * @returns
 */
export const urlFeatureFlags = (init = window.location.search) => {
    if (!flags) {
        const urlFeatureFlagsEntries = new URLSearchParams(init).entries();
        const urlFeatureFlagsFiltered = Array.from(urlFeatureFlagsEntries)
            .filter(([key, value]) => key.startsWith(URL_PREFIX) && Boolean(value))
            .map(([key, value]) => [key.replace(URL_PREFIX, ''), isTruthy(value)]);
        flags = Object.fromEntries(urlFeatureFlagsFiltered);
    }
    return flags;
};
