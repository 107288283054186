import { useMemo } from 'react';
/**
 * TODO(CS-8725): Channels for we need to use a different CDN. This is
 * supposed to be very temporary so using channel usernames is
 * the simplest way. Remove when no longer needed.
 *
 * List of customers with IP whitelists:
 * https://fwn.atlassian.net/wiki/x/AYAtMw
 */
export const preferredCDNsByChannel = {
    zeffo_testing: ['cdn4'],
    zenni_optical: ['cdn4'],
    demo_williamssonoma: ['cdn4'],
    sdbullion: ['cdn4'],
    regent_seven_seas_cruises: ['cdn4'],
    united_health_care: ['cdn4'],
    doveus: ['cdn4'],
    forever_new: ['cdn4'],
    lg: ['cdn4'],
    content_planning: ['cdn4'],
    pcrichardandson: ['cdn4'],
    zhu_shi_hui_she_kanpanizu: ['cdn4'],
    investhk: ['cdn4'],
};
/**
 * Returns the preferred CDNs for the given channel.
 *
 * @param params
 * @returns
 */
export const usePreferredCDNs = (params) => {
    return useMemo(() => (params.channel ? preferredCDNsByChannel[params.channel] : undefined), [params.channel]);
};
