import { useEffect } from 'react';
import { useApi } from '@core/api/hooks/useApi';
import { logWarning } from '@core/logging';
import { useStore } from '@core/widgets/store';
/**
 * @param params
 * @returns
 */
export function useWidgetConfigId(props) {
    const { widget_config_id } = props;
    const api = useApi();
    const actions = useStore((state) => state.actions);
    useEffect(() => {
        ;
        (async () => {
            try {
                if (widget_config_id) {
                    const response = await api.getConfigurationsByEmbedId(widget_config_id);
                    actions.setEmbedConfigParams(response.widget_properties);
                }
            }
            catch (error) {
                logWarning(`Error when fetching embed config params: ${error}`);
            }
        })();
    }, [widget_config_id, api, actions]);
}
