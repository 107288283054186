import { logWarning, safeLogVariable } from '@core/logging/helpers';
import { EnumFunnelTrackingCookies, getCookieWithFallback, setCookieWithFallback, } from '@core/tracking/funnelTracking';
/**
 * Read UTM from cookies/localStorage and returns object which is appended
 * to every tracking pixel.
 * @returns The UTM params object or undefined if no UTM params are found
 */
export function getUTM() {
    let value;
    try {
        value = getCookieWithFallback(EnumFunnelTrackingCookies.FW_UTM)?.value;
        if (!value) {
            return undefined;
        }
        try {
            // First try: direct JSON parse for backwards compatibility
            return JSON.parse(value);
        }
        catch {
            try {
                // Second try: decode base64 first, then JSON parse (older format)
                return JSON.parse(atob(value));
            }
            catch {
                // Third try: decode base64, then decodeURIComponent, then JSON parse (new format)
                return JSON.parse(decodeURIComponent(atob(value)));
            }
        }
    }
    catch (e) {
        logWarning(e, {
            captureContext: {
                extra: {
                    utms: safeLogVariable(value),
                },
            },
        });
        return undefined;
    }
}
/**
 * Set the UTM params in the cookie
 * @param obj - The UTM params object to set
 */
export function setUTM(obj) {
    // Note: we are not setting the cookie if all values are undefined
    // since empty `{}` value was breaking some customers' gateway (QA-1436).
    if (Object.values(obj).filter(Boolean).length > 0) {
        try {
            setCookieWithFallback(EnumFunnelTrackingCookies.FW_UTM, 
            // Encode the object to a base64 string to avoid special characters
            // that may break some secure gateways. (CS-8576)
            // First use encodeURIComponent to handle non-ASCII characters
            btoa(encodeURIComponent(JSON.stringify(Object.fromEntries(Object.entries(obj).filter(([_, value]) => Boolean(value)))))));
        }
        catch (e) {
            logWarning(e, {
                captureContext: {
                    extra: {
                        utms: safeLogVariable(obj),
                    },
                },
            });
        }
    }
}
