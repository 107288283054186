export var PlayTriggersEnum;
(function (PlayTriggersEnum) {
    PlayTriggersEnum["PLAY_TRIGGER_CLICK"] = "clickplay";
    /** Swipe up (right) for next video */
    PlayTriggersEnum["PLAY_TRIGGER_SWIPE_RIGHT"] = "swipeplay_right";
    /** Swipe down (left) for previous video */
    PlayTriggersEnum["PLAY_TRIGGER_SWIPE_LEFT"] = "swipeplay_left";
    PlayTriggersEnum["PLAY_TRIGGER_AUTOPLAY"] = "autoplay";
    PlayTriggersEnum["PLAY_TRIGGER_HOVERPLAY"] = "hoverplay";
    PlayTriggersEnum["PLAY_TRIGGER_INTERACTIVE"] = "interactive";
})(PlayTriggersEnum || (PlayTriggersEnum = {}));
