import React, { forwardRef, useState } from 'react';
import { BrokenImage } from '@core/assets/BrokenImage';
import { useContextTranslation } from '@core/i18n';
/**
 *
 */
export const Image = forwardRef(({ fallback, fallbackComponent = null, alt, role, src, onError, ...restProps }, ref) => {
    const [isFallback, setFallback] = useState(false);
    const { t } = useContextTranslation();
    // we can't do truthy checks on alt, because an empty string is technically
    // a valid value
    const hasAlt = alt !== undefined;
    if (!hasAlt && !role) {
        throw new Error(`Image requires one of alt text, or a role of either 'presentation' or 'none'`);
    }
    else if (hasAlt && (role === 'presentation' || role === 'none')) {
        throw new Error(`Image cannot have both alt text and a role of 'presentation' or 'none'`);
    }
    else if (!alt && role && role !== 'presentation' && role !== 'none') {
        throw new Error(`if Image alt is NOT set role MUST be either 'presentation' or 'none'`);
    }
    return !isFallback ? (<img onError={(e) => {
            setFallback(true);
            onError?.(e);
        }} alt={alt} role={role} src={src} {...restProps} ref={ref}/>) : fallbackComponent ? (<>{fallbackComponent}</>) : fallback ? (<img onError={(e) => {
            setFallback(true);
            onError?.(e);
        }} alt={alt} role={role} src={fallback} {...restProps} ref={ref}/>) : (<div role={role} ref={ref}>
        <BrokenImage label={t('broken image')} color={`color-mix(in srgb, currentColor, transparent 40%)`}/>
      </div>);
});
