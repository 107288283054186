import { ProductTagPlacement } from '@core/types';
import { FWNElement } from './bases/FWNElement';
class WidgetElement extends FWNElement {
    constructor() {
        super(...arguments);
        this.height = '75vh';
        this.width = '100%';
    }
    render() {
        const params = this.getAttributes();
        const widget_config_id = params?.widget_config_id;
        const layout = widget_config_id?.split('_')?.[1];
        if (layout === 'sb') {
            let widgetParams = params;
            widgetParams = {
                height: this.height,
                width: this.width,
                ...this.getAttributes(),
            };
            const computedHeight = parseInt(getComputedStyle(this).height);
            const computedWidth = parseInt(getComputedStyle(this).width);
            this.style.height = computedHeight
                ? getComputedStyle(this).height
                : this.height;
            this.style.width = computedWidth
                ? getComputedStyle(this).width
                : this.width; // Explicitly set width to force wrapping flex-box to respect it.
            import('@embed/storyblockRender').then(({ storyblockRender }) => {
                if (this.shadowRoot) {
                    storyblockRender({
                        target: this.shadowRoot,
                        widgetId: this.widgetId,
                        widgetParams,
                    });
                }
            });
        }
        else if (layout === 'fp') {
            let widgetParams = params;
            widgetParams = {
                ...params,
                mode: 'pinned',
            };
            import('@embed/storyblockRender').then(({ storyblockRender }) => {
                if (this.shadowRoot) {
                    storyblockRender({
                        target: this.shadowRoot,
                        widgetId: this.widgetId,
                        widgetParams,
                    });
                }
            });
        }
        else if (layout === 'hp') {
            // hp - hero player
            import('@embed/playerRender').then(({ playerRender }) => {
                if (this.shadowRoot) {
                    playerRender({
                        target: this.shadowRoot,
                        widgetId: this.widgetId,
                        widgetParams: {
                            height: '100%',
                            width: '100%',
                            ...params,
                        },
                    });
                }
            });
        }
        else if (['hu'].includes(layout)) {
            // hu - hero unit
            import('@embed/herounitRender').then(({ herounitRender }) => {
                if (this.shadowRoot) {
                    herounitRender({
                        target: this.shadowRoot,
                        widgetId: this.widgetId,
                        widgetParams: params,
                    });
                }
            });
        }
        else if (['efc', 'efg', 'pd', 'dc', 'hc'].includes(layout)) {
            // efc - embed feed carousel
            // efg - embed feed grid
            // pd - player deck
            // dc - dynamic thumbnail
            // hc - horizontal thumbnail
            let widgetParams = params;
            switch (layout) {
                case 'pd':
                    widgetParams = {
                        ...params,
                        player_deck_layout: true,
                        captions: false,
                        product_tag_placement: ProductTagPlacement.FALSE,
                    };
                    break;
                case 'dc':
                    widgetParams = {
                        ...params,
                        thumbnail_style: 'dynamic',
                    };
                    break;
                case 'hc':
                    widgetParams = {
                        ...params,
                        thumbnail_orientation: 'horizontal',
                    };
                    break;
            }
            import('@embed/embedFeedRender').then(({ embedFeedRender }) => {
                if (this.shadowRoot) {
                    embedFeedRender({
                        target: this.shadowRoot,
                        widgetId: this.widgetId,
                        widgetParams,
                        mode: layout === 'efg' ? 'grid' : 'row',
                    });
                }
            });
        }
    }
}
if (!customElements.get('fw-widget')) {
    customElements.define('fw-widget', WidgetElement);
}
