/**
 * @fileoverview
 *
 * Functions to initiate and control `_fwn.players`
 * SDK attribute which provides programmatic access
 * to all available players on a scene. Players are
 * accessible by a `widget_id` and `name` key.
 */
import { customEvent, EventType } from '@core/customEvent';
import { getFWN } from '@core/fwn';
import { PlayerControl } from './PlayerControl';
const allPlayersCache = {};
/**
 * Getter to access current module level cache
 */
export function fwnPlayersGetter() {
    return allPlayersCache;
}
/**
 * Register a player instance under certain keys (widget_id, name).
 */
export function registerFWNPlayer(widgetId, name, instance) {
    const fwn = getFWN();
    if (fwn) {
        const player = new PlayerControl(instance);
        fwn.players[widgetId] = player;
        if (name) {
            fwn.players[name] = player;
        }
        customEvent({
            type: EventType.FW_PLAYER_READY,
            data: { widgetId, name, player },
        });
        return player;
    }
}
/**
 * Unregister - destroy a player instance.
 */
export function unregisterFWNPlayer(widgetId, name) {
    const fwn = getFWN();
    if (fwn) {
        delete fwn.players[widgetId];
        if (name) {
            delete fwn.players[name];
        }
    }
}
