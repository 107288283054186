import isChromatic from 'chromatic/isChromatic';
/**
 * Returns SVG filter for drop shadow. Can be reused in other SVGs.
 *
 * Note:
 * Use `filter={props.shadow ? 'url(#shadowFilter)' : ''}` on the element/path
 * you want to apply the shadow to.
 */
export const ShadowFilter = (props) => {
    const noop = isChromatic();
    return (<filter id="shadowFilter" colorInterpolationFilters="sRGB">
      {noop ? (<feDropShadow dx="0" dy="0" stdDeviation="0" floodColor="transparent" floodOpacity="0"/>) : (<feDropShadow dx="0" dy="0" stdDeviation="1" floodColor="black" floodOpacity="0.35" {...props}/>)}
    </filter>);
};
