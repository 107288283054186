import { mergeVariables } from '../tools/mergeVariables';
import { theme as defaultTheme } from './default';
export const theme = {
    key: 'light',
    variables: mergeVariables(defaultTheme.variables, [
        ['--font-color', 'var(--blk1)'],
        ['--chat-pin-background', 'rgba(33,137,255, 1)'],
        ['--chat-reply-background', 'rgb(var(--white-rgb) / 75%)'],
        ['--chat-reply-font-color', 'var(--font-color)'],
        ['--chat-reply-initial-msg-background', 'rgb(var(--white-rgb) / 75%)'],
        ['--chat-reply-initial-msg-font-color', 'var(--font-color)'],
        ['--chat-reply-initial-msg-text-shadow', 'none'],
        ['--chat-reply-text-shadow', 'none'],
        //
        ['--player-background', 'rgba(0,0,0,0.9)'],
        ['--player-modal-background', 'var(--white)'],
        ['--player-modal-font-color', 'var(--font-color)'],
        [
            '--player-drawer-background',
            'color-mix(in srgb, var(--white) 85%, transparent)',
        ],
        ['--player-drawer-font-color', 'var(--font-color)'],
        ['--player-chat-background', 'var(--white)'],
        ['--player-chat-font-color', 'var(--font-color)'],
        ['--player-chat-text-shadow', 'none'],
        ['--player-header-font-color', 'var(--font-color)'],
        ['--player-pinned-font-color', 'var(--white)'],
        ['--player-shop-background', 'var(--white)'],
        ['--player-shop-font-color', 'var(--font-color)'],
        ['--player-modal-background', 'var(--white)'],
        ['--player-interaction-background', 'var(--white)'],
        ['--player-interaction-font-color', 'var(--blk2)'],
        //
        ['--product-card-background', 'var(--white)'],
        ['--product-card-font-color', 'var(--font-color)'],
        //
        ['--product-description-font-color', 'var(--font-color)'],
        ['--product-name-font-color', 'var(--font-color)'],
        ['--product-price-font-color', 'var(--font-color)'],
        ['--product-progress-bar-background', 'var(--gray6)'],
        //
        ['--player-deck-border-color', '#e1e4e8'],
        ['--player-deck-list-background', 'var(--white)'],
        [
            '--player-deck-item-background',
            'linear-gradient(135deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%)',
        ],
        [
            '--player-deck-item-background-hover',
            'linear-gradient(135deg, rgba(241, 241, 241, 0.81) 0.01%, rgba(241, 241, 241, 0.9) 62%)',
        ],
        ['--player-deck-product-card-discount-price-font-color', '#b60000'],
        ['--player-deck-product-drawer-trigger-foreground', 'var(--blk0)'],
        ['--player-deck-product-drawer-trigger-background', 'var(--white)'],
        ['--player-deck-cta-button-color', 'var(--blk0)'],
        ['--player-deck-cta-background-color', 'var(--white)'],
    ]),
};
