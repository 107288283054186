export const PROMO_URL = 'https://firework.com/video-solutions-for-entrepreneurs/?utm_source=website&utm_content=widget&utm_campaign=poweredbyfirework';
export var PlaySourceEnum;
(function (PlaySourceEnum) {
    PlaySourceEnum["AUTOPLAY"] = "autoplay";
    PlaySourceEnum["PLAYER"] = "player";
})(PlaySourceEnum || (PlaySourceEnum = {}));
/**
 * Represents the ready state of the video element:
 * 0 = HAVE_NOTHING - no information whether or not the video is ready
 * 1 = HAVE_METADATA - metadata for the video is ready
 * 2 = HAVE_CURRENT_DATA - data for the current playback position is available, but not enough data to play next frame/millisecond
 * 3 = HAVE_FUTURE_DATA - data for the current and at least the next frame is available
 * 4 = HAVE_ENOUGH_DATA - enough data available to start playing
 */
export var VideoWrapperReadyStatus;
(function (VideoWrapperReadyStatus) {
    VideoWrapperReadyStatus[VideoWrapperReadyStatus["HAVE_NOTHING"] = 0] = "HAVE_NOTHING";
    VideoWrapperReadyStatus[VideoWrapperReadyStatus["HAVE_METADATA"] = 1] = "HAVE_METADATA";
    VideoWrapperReadyStatus[VideoWrapperReadyStatus["HAVE_CURRENT_DATA"] = 2] = "HAVE_CURRENT_DATA";
    VideoWrapperReadyStatus[VideoWrapperReadyStatus["HAVE_FUTURE_DATA"] = 3] = "HAVE_FUTURE_DATA";
    VideoWrapperReadyStatus[VideoWrapperReadyStatus["HAVE_ENOUGH_DATA"] = 4] = "HAVE_ENOUGH_DATA";
})(VideoWrapperReadyStatus || (VideoWrapperReadyStatus = {}));
export var agoraConnectionState;
(function (agoraConnectionState) {
    agoraConnectionState["DISCONNECTED"] = "DISCONNECTED";
    agoraConnectionState["CONNECTING"] = "CONNECTING";
    agoraConnectionState["RECONNECTING"] = "RECONNECTING";
    agoraConnectionState["CONNECTED"] = "CONNECTED";
    agoraConnectionState["DISCONNECTING"] = "DISCONNECTING";
})(agoraConnectionState || (agoraConnectionState = {}));
export const DETACHED_PLAYER_ID = 'fw-detached-player';
