import { postMessageWrapper } from '@core/customEvent';
import { PostmessageSentinel } from '@core/customEvent/postMessageWrapper';
import { isVideoFeed } from '@core/feed';
import { isInPIPIframe } from '@core/pictureInPicture';
import { playerStateOpenedSelector } from '@core/player/model/selectors';
import { getGlobalStore } from '@core/store';
import { getSerializableAppContext } from '@embed/helpers';
/**
 * Delegates video to existing player by triggering `play` postMessage.
 * Caller has to be in PIP iframe or `fw-detached-player` has to exists in DOM.
 * @param props
 * @returns true if the player (or PIP iframe) was detected and video
 *    was delegated to player via postMessage, false otherwise
 */
export function delegateVideoToPlayer(props) {
    const { appContext, params, video, videos, feed } = props;
    // Player has to be open in a first place
    const playerState = playerStateOpenedSelector(getGlobalStore().getState());
    if (playerState && isInPIPIframe() && isVideoFeed(feed)) {
        const serializableAppContext = getSerializableAppContext(appContext, params, video);
        const updatedFeed = feed
            ? {
                ...feed,
                feed_items: videos.map((v) => {
                    const item = feed?.feed_items.find((item) => item.video.encoded_id === v.encoded_id);
                    return item || { variant: 'ad_insertion', video: v };
                }),
            }
            : null;
        postMessageWrapper({
            action: 'play',
            appContext: serializableAppContext,
            sentinel: PostmessageSentinel.INTERNAL,
            video,
            feed: updatedFeed,
        });
        return true;
    }
    return false;
}
