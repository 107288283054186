import { logWarning, safeLogVariable } from '@core/logging/helpers';
import { EnumFunnelTrackingCookies, getCookieWithFallback, } from '@core/tracking/funnelTracking';
/**
 * Read UTM from cookies/localStorage and returns object which is appended
 * to every tracking pixel
 */
export const getUTM = () => {
    let utms;
    try {
        utms = getCookieWithFallback(EnumFunnelTrackingCookies.FW_UTM)?.value;
        return utms ? JSON.parse(utms) : undefined;
    }
    catch (e) {
        logWarning(e, {
            captureContext: {
                extra: {
                    utms: safeLogVariable(utms),
                },
            },
        });
    }
};
