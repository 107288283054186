import * as themes from '../defs';
const DEFAULT_THEME = 'blended';
function getTheme(key) {
    return Object.values(themes).find((theme) => theme.key === key);
}
/**
 * Function to return theme based on its name
 * @param name
 * @returns
 */
export function loadTheme(key, defaultTheme = DEFAULT_THEME) {
    const theme = getTheme(key) || getTheme(defaultTheme);
    return theme;
}
