import { LIVESTREAM_SLICE_KEY } from './slice';
/**
 *
 */
export function heartbeatIntervalSelector(state) {
    return state[LIVESTREAM_SLICE_KEY].heartbeatInterval;
}
/**
 *
 */
export function joinedTopicsSelector(state) {
    return state[LIVESTREAM_SLICE_KEY].joinedTopics;
}
/**
 * @returns livestream model actions
 */
export function liveStreamActionsSelector(state) {
    return state[LIVESTREAM_SLICE_KEY].actions;
}
/**
 * @returns An active invitation token if it matches the video Id and has not
 *      been dismissed. Undefined otherwise.
 */
export function invitationTokenSelector(state, liveStreamId) {
    if (!liveStreamId) {
        return;
    }
    const { invitations, dismissedInvitations } = state[LIVESTREAM_SLICE_KEY];
    const invitationToken = invitations[liveStreamId];
    if (invitationToken &&
        !dismissedInvitations[liveStreamId]?.[invitationToken]) {
        return invitationToken;
    }
}
const defaultUsername = {
    name: '',
    isUserDefined: false,
};
/**
 * @returns channelUsername object for a given channel id
 */
export function usernameSelector(state) {
    // CS-3000: livestream slice can be undefined for unknown reasons.
    return (state[LIVESTREAM_SLICE_KEY]?.usernameHydrated ??
        (state[LIVESTREAM_SLICE_KEY]?.username || defaultUsername));
}
/**
 *
 * @returns boolean representing whether or not the username was hydrated via widget param
 */
export function hasHydratedUsernameSelector(state) {
    return !!state[LIVESTREAM_SLICE_KEY]?.usernameHydrated;
}
/**
 * @returns "active" user state for a given livestream id
 */
export function activeWatchSelector(state, liveStreamId) {
    if (!liveStreamId) {
        return false;
    }
    // CS-3000: livestream slice can be undefined for unknown reasons.
    return Boolean(state[LIVESTREAM_SLICE_KEY]?.activeWatches[liveStreamId]);
}
/**
 * @returns true if any livestream is active
 */
export function anyActiveWatchSelector(state) {
    return Object.values(state[LIVESTREAM_SLICE_KEY]?.activeWatches ?? {}).some((active) => active);
}
/**
 * Selector to get whether or not current user has entered a livestream's giveaway
 */
export function liveStreamGiveawaySelector(state, liveStreamId, interactionId) {
    if (!(liveStreamId && interactionId)) {
        return null;
    }
    return state[LIVESTREAM_SLICE_KEY].liveStreamGiveaways[liveStreamId]?.[interactionId];
}
