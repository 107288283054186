/* eslint-disable no-console */
/**
 * @fileoverview Helper logging methods that abstract away from the logging
 * services.
 */
import { isDebug } from '@core/debug';
import { isSafari } from '@core/userAgent';
import { eligibleForMessageLog, sentryConfig } from './config';
import { getSentry } from './sentryLazy';
export const debugWarningGradient = `
color: black;
padding: 2px 4px;
border-radius: 2px;
background: rgb(255,62,33);
background: linear-gradient(90deg, rgba(255,62,33,1) 35%, rgba(254,209,23,1) 100%);
`;
export const debugInfoGradient = `
color: white;
padding: 2px 4px;
border-radius: 2px;
background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(164,15,244,1) 35%, rgba(254,23,122,1) 100%);
`;
/** Logs an error to the sentry server. */
export const logError = (error, hint) => {
    getSentry().captureException(error, hint);
    if (isDebug()) {
        console.error(error, hint);
    }
};
/**
 * Logs a message to Sentry. Logs to console as well
 * if debug mode is on. Uses sample rate from Sentry config
 * unless new value between 0-1 is provided.
 *
 * @param message
 * @param hint
 */
export const logMessage = (message, hint, sampleRate) => {
    if (eligibleForMessageLog(sampleRate || sentryConfig.sampleRate)) {
        getSentry().captureMessage(message, hint);
    }
    logInfo(message);
};
/**
 * Log a warning to the browser's console - suitable to warn
 * integrations about some misconfigurations. Please note
 * this is not a replacement for console.warn, rather a public
 * facing messaging.
 */
export const logWarning = (...args) => {
    console.info('%cFW-WARNING', debugWarningGradient, ...args);
};
/**
 * Logs an info to the console when in debug mode.
 */
export const logInfo = (...args) => {
    if (isDebug()) {
        console.info(typeof window !== 'undefined' && window.self !== window.top
            ? '%cFW-DEBUG-iframe'
            : '%cFW-DEBUG', debugInfoGradient, ...args);
    }
};
/**
 * Logs info to the console as a collapsed group
 *
 * @param label - The label to display in the console.
 * @param args - The data to log.
 */
export const logInfoCollapsed = (label, ...args) => {
    logGroupCollapsed(label, () => {
        console.info(...args);
    });
};
/**
 * Logs debug message to the debug console as a collapsed group
 *
 * @param label - The label to display in the console.
 * @param content - Callback to produce the content.
 */
export const logGroupCollapsed = (label, content) => {
    if (isDebug()) {
        const labelPrefix = typeof window !== 'undefined' && window.self !== window.top
            ? 'FW-DEBUG-iframe'
            : 'FW-DEBUG';
        if (isSafari) {
            // Safari wont render formatting for group label
            console.groupCollapsed([labelPrefix, ':::', label].join(' '));
        }
        else {
            console.groupCollapsed(`%c${labelPrefix}`, debugInfoGradient, label);
        }
        content();
        console.groupEnd();
    }
};
/** Logs debug message to the debug console. */
export const logDebug = (message) => {
    if (isDebug()) {
        window?._fwn?.console?.instance?.log(`${new Date().toLocaleTimeString()}: ${message}`);
    }
};
/** Safely logs variable in sentry */
export function safeLogVariable(variable) {
    const replacer = (_, value) => {
        if (typeof value === 'function') {
            return '[Function]';
        }
        else if (typeof value === 'undefined') {
            return '[Undefined]';
        }
        else if (typeof value === 'symbol') {
            return `[Symbol: ${value.toString()}]`;
        }
        return value;
    };
    try {
        return JSON.stringify(variable, replacer);
    }
    catch (error) {
        return 'Circular or complex object';
    }
}
