import { uuidv4 } from '@embed/helpers';
/**
 *
 */
export function createAvaGlobalActions(set) {
    return {
        resetContexts: () => set({ contexts: [] }, false, 'resetContexts'),
        addContext: (context) => set((state) => {
            const newContexts = [
                ...state.contexts,
                {
                    ...context,
                    key: uuidv4(),
                },
            ];
            return { contexts: newContexts };
        }, false, 'addContext'),
        removeContext: (key) => set((state) => {
            const newContexts = state.contexts.filter((context) => context.key !== key);
            return { contexts: newContexts };
        }, false, 'removeContext'),
    };
}
