import { isFalsy } from '@core/helpers/boolean';
import { createBunchOfActivatedVideoElements } from '@core/video/videoElementWrapper/cache';
import { isIframe } from '@core/window';
import { customEmbedEvent, EventType } from '@embed/customEmbedEvent';
import { createLinkToEmbedVideoPage } from '@embed/helpers';
import { delegateVideoToPlayer } from './delegateVideoToPlayer';
/**
 *  Helping onClick events to unify thumbnail click
 *  behaviour (open in _self, _blank or open player)
 *
 * @param videos - Array of videos which are inside widget, can contain ad videos which are not inside
 *                 the feed, so helper will update feed with videos to be able to hydrate correctly
 *
 *  @return {Boolean} `true` to let component handle its own player open mechanics
 *  @return {Boolean} `false` video opened by browser
 */
export const onNavigateToVideoHelper = ({ params, appContext, video, videos, feed, createVideoElement = true, }) => {
    customEmbedEvent({
        type: EventType.FW_EMBEDFEED_THUMBNAILCLICK,
        params,
        data: { video },
    });
    let shouldOpenPlayer = true;
    if (params.open_in !== undefined && isFalsy(params.open_in)) {
        shouldOpenPlayer = false;
    }
    const isAmpFrame = params.location &&
        params.location.hash &&
        params.location.hash.search('amp=1') > -1;
    const newLocation = createLinkToEmbedVideoPage(appContext, params, video);
    // Player might be already running, see if we can delegate the
    // video play to it.
    if (delegateVideoToPlayer({
        params,
        appContext,
        video,
        videos,
        feed,
    })) {
        return false;
    }
    let openIn = !params.open_in || params.open_in === 'default' ? '_iframe' : params.open_in;
    let newWindow = null;
    // AMP iframe only allows blank and self
    // unless its in Cypress iframe
    if (!window.Cypress?.version &&
        !['_blank', '_self'].includes(openIn) &&
        (!params.open_in || params.open_in === 'default') &&
        (isIframe() || isAmpFrame)) {
        openIn = '_blank';
    }
    // Seems calling setting window.open
    // before await cools down popup blockers
    if (openIn === '_blank') {
        newWindow = window.open('', '_blank');
        if (newWindow) {
            newWindow.location = newLocation;
        }
        return false;
    }
    else if (openIn === '_self') {
        if (isIframe() || isAmpFrame) {
            newWindow = window.open('', '_top');
            if (newWindow) {
                newWindow.location = newLocation;
            }
        }
        else {
            window.location && (window.location.href = newLocation);
        }
        return false;
    }
    // Lets just create element for VideoPlayer
    // Doing so onClick helps us deliver unmuted/autoplay
    if (createVideoElement) {
        createBunchOfActivatedVideoElements();
    }
    return shouldOpenPlayer;
};
