/**
 * @fileoverview
 * Helper function to build appContext for embeds.
 */
import { logMessage } from '@core/logging';
import { pruneNilValues } from '@core/object';
import { getPresentationFromParams } from './getPresentationFromParams';
import { mapConfigToTrackingData } from './mapConfigToTrackingData';
/**
 *
 */
export function getAppContext({ appContextType, channel, embedFeedConfig, embedInstanceId, params, guestId, adConfig, }) {
    if (((params.channel || params.channel_id) && !channel) ||
        typeof adConfig === 'undefined') {
        return null;
    }
    if (params.playlist && !(params.channel || params.channel_id)) {
        logMessage('Widget with `playlist` is missing `channel`', {
            data: {
                playlist: params.playlist,
            },
        }, 1);
        return null;
    }
    const { trackingData, trackingDataPixel } = mapConfigToTrackingData(embedFeedConfig, channel) ?? {};
    if (!embedFeedConfig || !trackingData || !trackingDataPixel) {
        return null;
    }
    const presentation = getPresentationFromParams(appContextType, params);
    return {
        appContextType,
        params,
        username: channel?.username || params.channel || params.channel_id,
        embedFeedConfig,
        embedInstanceId,
        guestId,
        channel,
        adConfig,
        trackingData: {
            app_context_type: appContextType,
            embed_instance_id: embedInstanceId,
            ...trackingData,
            presentation,
            guest_id: guestId,
            page_url: params.page_url ?? '',
            product: 'embed.web.naboo',
        },
        trackingDataPixel: pruneNilValues({
            app_context_type: appContextType,
            embed_instance_id: embedInstanceId,
            ...trackingDataPixel,
            presentation,
            _widget_config_id: getWidgetConfigId(params?.widget_config_id),
        }),
    };
}
function getWidgetConfigId(widgetConfigId) {
    if (!widgetConfigId) {
        return undefined;
    }
    return widgetConfigId.split('_')[0];
}
