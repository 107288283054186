/**
 * @fileoverview
 * List of exposed CSS variables to provide customization options
 * to our client's integrations.
 * See also https://fwn.atlassian.net/wiki/spaces/WP/pages/204734469/Configuration+CSS+Variables
 *
 * Note: Please keep sorted alphabetically.
 */
/**
 * Commonly used variables by all widgets.
 */
// Primary color of CTA button. --fw-primary is used as a fallback.
export const BUTTON_COLOR = '--fw-button-color';
// Font color of CTA button.
export const BUTTON_FONT_COLOR = '--fw-button-font-color';
// Global font color affecting all texts and UI elements. Defaults to "white".
//Font weight of CTA button.
export const BUTTON_FONT_WEIGHT = '--fw-button-font-weight';
//Border of CTA button.
export const BUTTON_BORDER = '--fw-button-border';
export const FONT_COLOR = '--fw-font-color';
// Global font family. Defaults to "Avenir Next"
export const FONT_FAMILY = '--fw-font-family';
export const GRADIENT = '--fw-gradient';
export const PRIMARY = '--fw-primary';
export const BRANDING_TEXT_ALIGN = '--fw-branding-text-align';
/**
 * Add to calendar widget
 */
export const ADD_TO_CALENDAR_BACKGROUND = '--fw-add-to-calendar-background';
export const ADD_TO_CALENDAR_BORDER = '--fw-add-to-calendar-border';
export const ADD_TO_CALENDAR_COLOR = '--fw-add-to-calendar-color';
export const ADD_TO_CALENDAR_FONT_FAMILY = '--fw-add-to-calendar-font-family';
export const ADD_TO_CALENDAR_FONT_SIZE = '--fw-add-to-calendar-font-size';
export const ADD_TO_CALENDAR_FONT_WEIGHT = '--fw-add-to-calendar-font-weight';
export const ADD_TO_CALENDAR_HEIGHT = '--fw-add-to-calendar-height';
export const ADD_TO_CALENDAR_HOVER_BACKGROUND = '--fw-add-to-calendar-hover-background';
export const ADD_TO_CALENDAR_HOVER_BORDER = '--fw-add-to-calendar-hover-border';
export const ADD_TO_CALENDAR_HOVER_COLOR = '--fw-add-to-calendar-hover-color';
export const ADD_TO_CALENDAR_HOVER_FONT_SIZE = '--fw-add-to-calendar-hover-font-size';
export const ADD_TO_CALENDAR_HOVER_FONT_WEIGHT = '--fw-add-to-calendar-hover-font-weight';
export const ADD_TO_CALENDAR_WIDTH = '--fw-add-to-calendar-width';
/**
 * Embed-feed (carousel) widget
 */
export const THUMBNAIL_BORDER_RADIUS = '--fw-thumbnail-border-radius'; //tested
export const THUMBNAIL_FONT_SIZE = '--fw-thumbnail-font-size'; //tested
export const THUMBNAIL_FONT_WEIGHT = '--fw-thumbnail-font-weight'; //tested
export const THUMBNAIL_GAP = '--fw-thumbnail-gap'; //tested
export const THUMBNAIL_ASPECT_RATIO = '--fw-thumbnail-aspect-ratio'; //tested
export const THUMBNAIL_HEIGHT = '--fw-thumbnail-height'; //tested
export const THUMBNAIL_WIDTH = '--fw-thumbnail-width'; //tested
/**
 * Player in storyblock and other widgets (chat, shop, etc.)
 */
export const PLAYER_WIDTH = '--fw-player-width';
export const PLAYER_MARGIN = '--fw-player-margin';
export const PLAYER_HEIGHT = '--fw-player-height';
export const PLAYER_BACKGROUND = '--fw-player-background';
export const PLAYER_BORDER_RADIUS = '--fw-player-border-radius';
export const PLAYER_BOX_SHADOW = '--fw-player-box-shadow';
// Player chat affects chat
export const PLAYER_CAPTION_BACKGROUND = '--fw-player-caption-background';
export const PLAYER_CAPTION_COLOR = '--fw-player-caption-color';
export const PLAYER_CHAT_BACKGROUND = '--fw-player-chat-background';
export const PLAYER_CHAT_FONT_COLOR = '--fw-player-chat-font-color';
export const PLAYER_CHAT_FONT_FAMILY = '--fw-player-chat-font-family';
export const PLAYER_CHAT_TEXT_SHADOW = '--fw-player-chat-text-shadow';
// Player header affects headers of different sections (shop)
export const PLAYER_HEADER_FONT_COLOR = '--fw-player-header-font-color';
export const PLAYER_HEADER_FONT_FAMILY = '--fw-player-header-font-family';
// Player pinned affects pinned products
export const PLAYER_PINNED_BORDER_RADIUS = '--fw-player-pinned-border-radius';
export const PLAYER_PINNED_FONT_COLOR = '--fw-player-pinned-font-color';
export const PLAYER_PINNED_FONT_FAMILY = '--fw-player-pinned-font-family';
// Player shop affects shopping bag, pdp and checkout/cart
export const PLAYER_SHOP_BACKGROUND = '--fw-player-shop-background';
export const PLAYER_SHOP_FONT_COLOR = '--fw-player-shop-font-color';
export const PLAYER_SHOP_LIST_ITEM_HOVER_BACKGROUND = '--fw-player-shop-list-item-hover-background'; // ??deprecate
// Player modal affect modals
export const PLAYER_MODAL_BACKGROUND = '--fw-player-modal-background';
export const PLAYER_MODAL_COLOR = '--fw-player-modal-font-color';
// Player interaction affects interactions
export const PLAYER_INTERACTION_BACKGROUND = '--fw-player-interaction-background';
export const PLAYER_INTERACTION_COLOR = '--fw-player-interaction-color';
/**
 * Share button widget
 */
export const SHARE_BORDER = '--fw-share-border';
export const SHARE_COLOR = '--fw-share-color';
export const SHARE_FONT_FAMILY = '--fw-share-font-family';
export const SHARE_FONT_SIZE = '--fw-share-font-size';
export const SHARE_FONT_WEIGHT = '--fw-share-font-weight';
export const SHARE_HEIGHT = '--fw-share-height';
export const SHARE_HOVER_BORDER = '--fw-share-hover-border';
export const SHARE_HOVER_COLOR = '--fw-share-hover-color';
export const SHARE_HOVER_FONT_SIZE = '--fw-share-hover-font-size';
export const SHARE_HOVER_FONT_WEIGHT = '--fw-share-hover-font-weight';
export const SHARE_WIDTH = '--fw-share-width';
export const SHARE_BACKGROUND = '--fw-share-background';
export const SHARE_HOVER_BACKGROUND = '--fw-share-hover-background';
/**
 * Storyblock widget
 */
export const STORYBLOCK_BACKGROUND = '--fw-storyblock-background';
export const STORYBLOCK_BORDER_RADIUS = '--fw-storyblock-border-radius';
/**
 * Chat replies
 */
export const CHAT_REPLY_BACKGROUND = '--fw-chat-reply-background'; // ??deprecate
export const CHAT_REPLY_BORDER_RADIUS = '--fw-chat-reply-border-radius';
export const CHAT_REPLY_FONT_COLOR = '--fw-chat-reply-font-color';
export const CHAT_REPLY_FONT_FAMILY = '--fw-chat-reply-font-family';
export const CHAT_REPLY_TEXT_SHADOW = '--fw-chat-reply-text-shadow';
export const CHAT_REPLY_INITIAL_MSG_BACKGROUND = '--fw-chat-reply-initial-msg-background'; // ?deprecate
export const CHAT_REPLY_INITIAL_MSG_FONT_COLOR = '--fw-chat-reply-initial-msg-font-color';
export const CHAT_REPLY_INITIAL_MSG_FONT_FAMILY = '--fw-chat-reply-initial-msg-font-family';
export const CHAT_REPLY_INITIAL_MSG_TEXT_SHADOW = '--fw-chat-reply-initial-msg-text-shadow';
/**
 * Chat pins
 */
export const CHAT_PIN_BACKGROUND = '--fw-chat-pin-background';
export const CHAT_PIN_BORDER_RADIUS = '--fw-chat-pin-border-radius';
/**
 * Product
 */
export const PRODUCT_NAME_FONT_COLOR = '--fw-product-name-font-color';
export const PRODUCT_NAME_FONT_FAMILY = '--fw-product-name-font-family';
export const PRODUCT_PRICE_FONT_COLOR = '--fw-product-price-font-color';
export const PRODUCT_PRICE_FONT_FAMILY = '--fw-product-price-font-family';
export const PRODUCT_DESCRIPTION_FONT_COLOR = '--fw-product-description-font-color';
export const PRODUCT_DESCRIPTION_FONT_FAMILY = '--fw-product-description-font-family';
export const PRODUCT_NAME_TEXT_TRANSFORM = '--fw-product-name-text-transform';
export const PRODUCT_NAME_LETTER_SPACING = '--fw-product-name-letter-spacing';
export const PRODUCT_NAME_FONT_WEIGHT = '--fw-product-name-font-weight';
export const PRODUCT_IMAGE_BACKGROUND_COLOR = '--fw-product-image-background-color';
/**
 * Livestream Wing
 */
export const LIVESTREAM_WING_HEIGHT = '--fw-ls-wing-height'; // ??deprecate
/**
 * Ad Badge
 */
export const AD_BADGE_BACKGROUND = '--fw-ad-badge-background';
export const AD_BADGE_FONT_COLOR = '--fw-ad-badge-font-color';
/**
 * Hero Unit
 */
export const HERO_UNIT_COUNTDOWN_TEXT_TRANSFORM = '--fw-hero-unit-countdown-text-transform';
export const HERO_UNIT_COUNTDOWN_LETTER_SPACING = '--fw-hero-unit-countdown-letter-spacing';
export const HERO_UNIT_COUNTDOWN_FONT_SIZE = '--fw-hero-unit-countdown-font-size';
export const HERO_UNIT_COUNTDOWN_FONT_WEIGHT = '--fw-hero-unit-countdown-font-weight';
export const HERO_UNIT_COUNTDOWN_FLEX = '--fw-hero-unit-countdown-flex';
export const HERO_UNIT_TITLE_FONT_FAMILY = '--fw-hero-unit-title-font-family';
export const HERO_UNIT_TITLE_FONT_SIZE = '--fw-hero-unit-title-font-size';
export const HERO_UNIT_TITLE_FONT_WEIGHT = '--fw-hero-unit-title-font-weight';
export const HERO_UNIT_TITLE_LINE_HEIGHT = '--fw-hero-unit-title-line-height';
export const HERO_UNIT_TITLE_FLEX = '--fw-hero-unit-title-flex';
export const HERO_UNIT_TITLE_TEXT_TRANSFORM = '--fw-hero-unit-title-text-transform';
export const HERO_UNIT_TITLE_LETTER_SPACING = '--fw-hero-unit-title-letter-spacing';
export const HERO_UNIT_CONTENT_TEXT_TRANSFORM = '--fw-hero-unit-content-text-transform';
export const HERO_UNIT_CONTENT_LETTER_SPACING = '--fw-hero-unit-content-letter-spacing';
export const HERO_UNIT_CONTENT_FONT_WEIGHT = '--fw-hero-unit-content-font-weight';
export const HERO_UNIT_CTA_TEXT_TRANSFORM = '--fw-hero-unit-cta-text-transform';
export const HERO_UNIT_CTA_LETTER_SPACING = '--fw-hero-unit-cta-letter-spacing';
export const HERO_UNIT_CTA_FONT_WEIGHT = '--fw-hero-unit-cta-font-weight';
export const HERO_UNIT_CTA_FONT_SIZE = '--fw-hero-unit-cta-font-size';
export const HERO_UNIT_START_TIME_FONT_SIZE = '--fw-hero-unit-start-time-font-size';
export const HERO_UNIT_START_TIME_FONT_WEIGHT = '--fw-hero-unit-start-time-font-weight';
export const HERO_UNIT_START_TIME_LETTER_SPACING = '--fw-hero-unit-start-time-letter-spacing';
export const HERO_UNIT_START_TIME_TEXT_TRANSFORM = '--fw-hero-unit-start-time-text-transform';
export const HERO_UNIT_START_TIME_LINE_HEIGHT = '--fw-hero-unit-start-time-line-height';
