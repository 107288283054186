import { useCallback, useRef } from 'react';
import { pruneNilValues } from '@core/object';
import { appContextToTrackingDataPixel, sendPixelTrackingWithSession, } from '@core/tracking';
import { useStoreApi } from '@core/widgets/store';
import { FEED_CREATE_FEED_IMPRESSION } from '@core/widgets/tracking/endpoints';
/**
 * Sends embed impression event and makes sure it does it only once.
 */
export const useTrackEmbedImpression = () => {
    const isTracked = useRef(false);
    const storeApi = useStoreApi();
    return useCallback((appContext) => {
        if (!isTracked.current) {
            isTracked.current = true;
            const { params } = storeApi.getState();
            const pixelTrackingData = appContextToTrackingDataPixel({ appContext });
            const pixelData = {
                event_properties: pruneNilValues({
                    autoplay: params.autoplay,
                    placement: params.placement,
                    ui_player_style: params?.ui_player_style,
                    ui_theme: params?.theme || params?.ui_theme,
                    ...pixelTrackingData,
                }),
            };
            sendPixelTrackingWithSession({
                endpoint: FEED_CREATE_FEED_IMPRESSION,
                data: pixelData,
                host: params?.api_host_tracking,
            });
        }
    }, [storeApi]);
};
