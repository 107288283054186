import { getSmartButton } from './services/button';
import { createEmbedPlaylistFeed, createEmbedUserProductSKUFeed, createEmbedUserTimelineFeed, getChannel, } from './services/feed';
import { setupRedaxiosClient } from './setupRedaxiosClient';
/**
 * Creates an API service with the given API host and context. Many API functions
 * are async loaded to reduce bundle size.
 */
export const createApiService = (apiHost, context, __setupFunc = setupRedaxiosClient) => {
    const api = __setupFunc(apiHost, context);
    return {
        // Feed (essential, sync loaded)
        getChannel(...args) {
            return getChannel(api, ...args);
        },
        createEmbedUserTimelineFeed(...args) {
            return createEmbedUserTimelineFeed(api, ...args);
        },
        createEmbedPlaylistFeed(...args) {
            return createEmbedPlaylistFeed(api, ...args);
        },
        createEmbedUserProductSKUFeed(...args) {
            return createEmbedUserProductSKUFeed(api, ...args);
        },
        // Feed (non-essential, async loaded)
        async getChannelById(...args) {
            const m = await require('./services/feed');
            return await m.getChannelById(api, ...args);
        },
        async createEmbedUserTimelineHashtagFeed(...args) {
            const m = await import('./services/feed');
            return await m.createEmbedUserTimelineHashtagFeed(api, ...args);
        },
        async createEmbedPlaylistCollectionFeed(...args) {
            const m = await import('./services/feed');
            return await m.createEmbedPlaylistCollectionFeed(api, ...args);
        },
        async createShowroomScheduleFeed(...args) {
            const m = await import('./services/feed');
            return await m.createShowroomScheduleFeed(api, ...args);
        },
        async getMoreFeedItems(...args) {
            const m = await import('./services/feed');
            return await m.getMoreFeedItems(api, ...args);
        },
        // Button Wrapper (essential)
        getSmartButton(...args) {
            return getSmartButton(api, ...args);
        },
        // Livestream
        async getLiveStreamMessages(...args) {
            const m = await import('./services/livestream');
            return await m.getLiveStreamMessages(api, ...args);
        },
        async getLiveStreamPrivateMessages(...args) {
            const m = await import('./services/livestream');
            return await m.getLiveStreamPrivateMessages(api, ...args);
        },
        async getLiveStreamEvents(...args) {
            const m = await import('./services/livestream');
            return await m.getLiveStreamEvents(api, ...args);
        },
        async getLiveStreamStateSnapshot(...args) {
            const m = await import('./services/livestream');
            return await m.getLiveStreamStateSnapshot(api, ...args);
        },
        // Microsites
        async getMicrositeByChannel(...args) {
            const m = await import('./services/microsites');
            return await m.getMicrositeByChannel(api, ...args);
        },
        async getMicrositeByChannelId(...args) {
            const m = await import('./services/microsites');
            return await m.getMicrositeByChannelId(api, ...args);
        },
        async getSitemapVideosByChannel(...args) {
            const m = await import('./services/microsites');
            return await m.getSitemapVideosByChannel(api, ...args);
        },
        async getSitemapPagesByChannelId(...args) {
            const m = await import('./services/microsites');
            return await m.getSitemapPagesByChannelId(api, ...args);
        },
        // Uncategorized
        async getVideo(...args) {
            const m = await import('./services/uncategorized');
            return await m.getVideo(api, ...args);
        },
        async getHashtag(...args) {
            const m = await import('./services/uncategorized');
            return await m.getHashtag(api, ...args);
        },
        async createVideoInteractionResult(...args) {
            const m = await import('./services/uncategorized');
            return await m.createVideoInteractionResult(api, ...args);
        },
        async createTriviaGiveawayResult(...args) {
            const m = await import('./services/uncategorized');
            return await m.createTriviaGiveawayResult(api, ...args);
        },
        async getRemindMe(...args) {
            const m = await import('./services/uncategorized');
            return await m.getRemindMe(api, ...args);
        },
        async getVideoEmailEmbed(...args) {
            const m = await import('./services/uncategorized');
            return await m.getVideoEmailEmbed(api, ...args);
        },
        async checkVideoAccessCode(...args) {
            const m = await import('./services/uncategorized');
            return await m.checkVideoAccessCode(api, ...args);
        },
        async getPlaylistFromCohorts(...args) {
            const m = await import('./services/uncategorized');
            return await m.getPlaylistFromCohorts(api, ...args);
        },
        async getConfigurationsByEmbedId(...args) {
            const m = await import('./services/uncategorized');
            return await m.getConfigurationsByEmbedId(api, ...args);
        },
    };
};
