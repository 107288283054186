import React from 'react';
import styled from '@emotion/styled';
import { THUMBNAIL_HEIGHT, THUMBNAIL_WIDTH } from '@core/styles/cssVariables';
import { THUMBNAIL_CONTAINER } from '@player/testIds';
import { THUMBNAIL_SIZE_LARGE, THUMBNAIL_SIZE_MEDIUM, THUMBNAIL_SIZE_PRODUCT_CARD_BOTTOM, THUMBNAIL_SIZE_SMALL, } from '../../styled';
const [WIDTH_MEDIUM, HEIGHT_MEDIUM] = THUMBNAIL_SIZE_MEDIUM;
const [WIDTH_SMALL, HEIGHT_SMALL] = THUMBNAIL_SIZE_SMALL;
const [WIDTH_LARGE, HEIGHT_LARGE] = THUMBNAIL_SIZE_LARGE;
const [WIDTH_PRODUCT_CARD_BOTTOM, HEIGHT_PRODUCT_CARD_BOTTOM] = THUMBNAIL_SIZE_PRODUCT_CARD_BOTTOM;
/**
 * Thumbnail wrapper to provide 1,65 aspect ratio
 * for its children. Pinned and row mode uses exact size 154x250.
 */
const Inner = styled.div `
  box-sizing: border-box;

  overflow: hidden;
  padding-top: 0;
  position: relative;

  ${(props) => `
    aspect-ratio: var(--thumbnail-aspect-ratio, ${props.width} / ${props.height});
    flex: 0 0 var(${THUMBNAIL_WIDTH}, ${props.width}px);
    height: var(${THUMBNAIL_HEIGHT}, ${props.height}px);
    width: var(${THUMBNAIL_WIDTH}, ${props.width}px);
  `}

  // Horizontal layout if params.thumbnail_style is horizontal
  ${(props) => props.hasHorizontalLayout &&
    `
    aspect-ratio: 16/9;
    flex: 0 0 auto;
    height: var(${THUMBNAIL_HEIGHT}, 240px);
    width: auto;
  `}

  ${(props) => props.displayMode === 'grid' &&
    `
    flex: 0;
    height: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 165%;
    width: 100%;
  `}
`;
const Outer = styled.div `
  border-radius: var(--border-radius);
  ${({ hasInteractionWithPlayerDeck }) => hasInteractionWithPlayerDeck
    ? `
    border-radius: var(--border-radius) var(--border-radius) 0 0;
  `
    : ''}
  ${({ showV2Card }) => showV2Card
    ? `
    border-radius: 0;
  `
    : ''}

  // [CS-2501] Do not remove the "url" part of the background-image property.
  // It serves to improve the LCP web vital by enabling <Placeholder> as a LCP candidate.
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPAAAAGQBAMAAABi+PBPAAAALVBMVEXOzs7T09PW1tbZ2dni4uLf39/s7OzQ0NDo6Ojk5OTb29vq6urm5ubd3d3u7u7SSsE5AAACR0lEQVR42u3TMUorURgFYDvrTGP9MjswXepYpH+WaW0CopVbEGJpI9jZCbYhkA1EsE0j2KbMGszcfybjVQloJeT77wIO5x6+g/OzuHm6yXXcS7r7u7jB9k7re0v39BjX2163vc72Lq/SHR5kt16vVil5Ok7Rs1lKXtxE9HNKHt5G7v/B4OQkJS9HEf2akvsPkVv2esfHKbYoukWT2+kcHaXoi4uPwW3jKrltXCW3jZvkpvFo86JyNG6St4Wr3H9N47jPjdf1b4+z347GL88R3eRW0VF5tEyVXyO6bVyWdW632+R2onL+2z/YOGsc92XjMtu42L3xeb3xNKLrjRcRXWcPs43TyLFx/dn9fONo3ORW0Ts2Hs+nbeXNwouoXDcefmq83Ly08VM07reNy8gtssaXOzaOxvNZRC+yjYdfNl5movr5xnnjb3/77zieTDLH93vgeLZ3jiccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcccc8wxxxxzzDHHHHPMMcd77Hj1I8eR/SvHhx9++x1urtNRptEvkAAAAABJRU5ErkJggg==');
  background-size: cover;

  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: translateZ(0);
  width: 100%;
`;
/**
 *
 */
const ThumbnailWrapper = ({ mode, isLarge, isSmall, children, label, isPlaceholder, showV2Card, productCarouselBottomLayout = false, hasInteractionWithPlayerDeck, hasHorizontalLayout, ...rest }) => {
    // Large carousel experiment
    const width = productCarouselBottomLayout
        ? WIDTH_PRODUCT_CARD_BOTTOM
        : isLarge
            ? WIDTH_LARGE
            : isSmall
                ? WIDTH_SMALL
                : WIDTH_MEDIUM;
    const height = productCarouselBottomLayout
        ? HEIGHT_PRODUCT_CARD_BOTTOM
        : isLarge
            ? HEIGHT_LARGE
            : isSmall
                ? HEIGHT_SMALL
                : HEIGHT_MEDIUM;
    return (<Inner role="group" aria-roledescription="slide" aria-label={label} displayMode={mode} height={height} width={width} hasHorizontalLayout={hasHorizontalLayout} {...rest}>
      <Outer showV2Card={!!showV2Card && !isPlaceholder} data-testid={THUMBNAIL_CONTAINER} hasInteractionWithPlayerDeck={hasInteractionWithPlayerDeck}>
        {children}
      </Outer>
    </Inner>);
};
export default ThumbnailWrapper;
