import { useChannel } from '@core/channel';
import { urlFeatureFlags } from './urlFeatureFlags';
/**
 * Returns feature flags for the given channel.
 * @param params
 */
export function useFeatureFlags({ channel, channel_id, target, }) {
    const channelInfo = useChannel({
        username: channel,
        encodedId: channel_id,
        target: target,
    });
    const featureFlags = {
        ...channelInfo?.config?.feature_flags,
        ...urlFeatureFlags(),
    };
    return featureFlags || {};
}
