/**
 * @fileoverview
 * Helper to determine `presentation` base on app context (and params)
 * for tracking payload.
 */
import { IPresentation, } from '@core/types';
import { AppContextType } from '@embed/components/helpers';
/**
 *
 */
export const getPresentationFromParams = (appContextType, params) => {
    if (params.widget_presentation === IPresentation.STORYBLOCK_PRODUCT_GALLERY) {
        return IPresentation.STORYBLOCK_PRODUCT_GALLERY;
    }
    switch (appContextType) {
        case AppContextType.EMBED_BUTTON_WRAPPER:
            return IPresentation.BUTTON_WRAPPER;
        case AppContextType.EMBED_ADD_TO_CALENDAR_CONTEXT_TYPE:
            return IPresentation.ADD_TO_CALENDAR;
        case AppContextType.EMBED_SHARE_CONTEXT_TYPE:
            return IPresentation.SHARE;
        case AppContextType.EMBED_HERO_CONTEXT_TYPE:
            return IPresentation.HERO;
        case AppContextType.EMBED_PLAYER_TYPE:
            return IPresentation.PLAYER;
        case AppContextType.EMBED_STORYBLOCK_CONTEXT_TYPE:
            return IPresentation.STORYBLOCK;
        case AppContextType.EMBED_STORYLINK_CONTEXT_TYPE:
            return IPresentation.STORYLINK;
        case AppContextType.EMBED_SHOWROOM_SCHEDULE_CONTEXT_TYPE:
            return IPresentation.SHOWROOM_SCHEDULE;
        case AppContextType.EMBED_CONTEXT_TYPE:
        case AppContextType.EMBED_GRID_CONTEXT_TYPE: {
            if (params.thumbnail_style === 'dynamic') {
                return IPresentation.DYNAMIC_CAROUSEL;
            }
            if (params.thumbnail_orientation === 'horizontal') {
                return IPresentation.HORIZONTAL_CAROUSEL;
            }
            if (params.player_deck_layout) {
                return IPresentation.PLAYER_DECK;
            }
            switch (params.mode) {
                case 'grid':
                    return IPresentation.GRID;
                default:
                    return IPresentation.CAROUSEL;
            }
        }
        case AppContextType.HEADLESS_FEED:
            return IPresentation.CAROUSEL;
        default:
            throw new Error('Unknown appContexType');
    }
};
