/**
 * @fileoverview Lazy implementation of Sentry Hub.
 * We lazy instantiate the Sentry hub to defer download the large sentry/browser
 * package.
 * We use the Sentry browser client instead of the default init method because
 * our sentry client should only cover the errors reported from the scope of the
 * widget.
 */
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _FwSentry_instances, _FwSentry_initSentryScope;
import { addInboundFilter } from './eventProcessors/addInboundFilters';
import { addUserAgent } from './eventProcessors/addUserAgent';
import { sentryConfig } from './config';
let fwSentryConfig;
let fwSentry;
let sentryScopePromise;
class FwSentryConfig {
    constructor() {
        this.tags = [];
        this.sampleRate = undefined;
        this.tracesSampleRate = undefined;
        this.profilesSampleRate = undefined;
        this.replaysSampleRate = undefined;
        this.replaysOnErrorSampleRate = undefined;
    }
    addTag(name, value) {
        this.tags.push([name, value]);
    }
    setSampleRate(rate) {
        this.sampleRate = rate;
    }
    setupTracing(tracesSampleRate) {
        this.tracesSampleRate = tracesSampleRate;
    }
    setupProfiling(profilesSampleRate) {
        this.profilesSampleRate = profilesSampleRate;
    }
    setupReplays(replaysSampleRate, replaysOnErrorSampleRate = 1) {
        this.replaysSampleRate = replaysSampleRate;
        this.replaysOnErrorSampleRate = replaysOnErrorSampleRate;
    }
}
/**
 *
 */
export class FwSentry {
    /**
     *
     */
    constructor(config) {
        _FwSentry_instances.add(this);
        this.fwSentryConfig = config;
    }
    /** Client code should call `captureMessage` from logging.ts */
    async captureMessage(message, hint) {
        const scope = await __classPrivateFieldGet(this, _FwSentry_instances, "m", _FwSentry_initSentryScope).call(this);
        scope.captureMessage(message, 'info', hint);
    }
    /** Client code should call `captureException` from logging.ts */
    async captureException(error, hint) {
        const scope = await __classPrivateFieldGet(this, _FwSentry_instances, "m", _FwSentry_initSentryScope).call(this);
        scope.captureException(error, hint);
    }
    /**
     * Disable lazy loading and initialize Sentry immediately.
     * This is required for performance monitoring to work
     */
    async disableLazyLoading() {
        await __classPrivateFieldGet(this, _FwSentry_instances, "m", _FwSentry_initSentryScope).call(this);
    }
}
_FwSentry_instances = new WeakSet(), _FwSentry_initSentryScope = function _FwSentry_initSentryScope() {
    if (sentryScopePromise) {
        return sentryScopePromise;
    }
    sentryScopePromise = import(
    /* webpackChunkName: 'sentry-browser' */ '@sentry/browser').then(({ BrowserClient, Scope, makeFetchTransport, defaultStackParser, breadcrumbsIntegration, globalHandlersIntegration, linkedErrorsIntegration, dedupeIntegration, browserTracingIntegration, browserProfilingIntegration, replayIntegration, }) => {
        const sentryScope = new Scope();
        const excludedDomains = ['chromatic.com'];
        const browserClientOptions = {
            transport: makeFetchTransport,
            stackParser: defaultStackParser,
            integrations: [
                breadcrumbsIntegration(),
                globalHandlersIntegration(),
                linkedErrorsIntegration(),
                dedupeIntegration(),
            ],
            ...sentryConfig,
            beforeSend(event) {
                const url = 
                // checks the direct url of the request
                event.request?.url ||
                    // checks the first frame of the stack trace
                    event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename ||
                    '';
                if (excludedDomains.some((domain) => url.includes(domain))) {
                    return null;
                }
                return event;
            },
        };
        if (this.fwSentryConfig.sampleRate) {
            browserClientOptions.sampleRate = this.fwSentryConfig.sampleRate;
        }
        if (this.fwSentryConfig.tracesSampleRate) {
            browserClientOptions.tracesSampleRate =
                this.fwSentryConfig.tracesSampleRate;
            browserClientOptions.integrations.push(browserTracingIntegration());
        }
        if (this.fwSentryConfig.profilesSampleRate) {
            browserClientOptions.profilesSampleRate =
                this.fwSentryConfig.profilesSampleRate;
            browserClientOptions.integrations.push(browserProfilingIntegration());
        }
        if (this.fwSentryConfig.replaysSampleRate) {
            browserClientOptions.replaysSessionSampleRate =
                this.fwSentryConfig.replaysSampleRate;
            browserClientOptions.replaysOnErrorSampleRate =
                this.fwSentryConfig.replaysOnErrorSampleRate;
            browserClientOptions.integrations.push(replayIntegration());
        }
        const client = new BrowserClient(browserClientOptions);
        sentryScope.setClient(client);
        sentryScope.setTag('fwn_origin', window.location?.origin ?? '');
        this.fwSentryConfig.tags.forEach(([name, value]) => sentryScope.setTag(name, value));
        addUserAgent(sentryScope);
        addInboundFilter(sentryScope);
        client.init();
        return sentryScope;
    });
    return sentryScopePromise;
};
/**
 * @returns Sentry hub singleton instance
 */
export const getSentryConfig = () => {
    if (!fwSentryConfig) {
        fwSentryConfig = new FwSentryConfig();
    }
    return fwSentryConfig;
};
/**
 * @returns FW Sentry singleton
 */
export const getSentry = () => {
    if (!fwSentry) {
        fwSentry = new FwSentry(getSentryConfig());
    }
    return fwSentry;
};
export const TEST_ONLY = {
    reset: () => {
        fwSentryConfig = undefined;
        sentryScopePromise = undefined;
    },
};
